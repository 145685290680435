<template>
   <div class="row">
      <div class="col-12">

         <div v-if="isLoading" class="d-flex justify-content-center">{{ $t('loading') }}</div>

         <!-- Default box -->
         <div v-else class="card">
            <div class="card-header">
               <div class="card-tools">
                  <div class="input-group input-group-sm" style="width: 350px;">
                     <label class="text-secondary text-sm">{{ $t('select_a_partner') }}</label>
                     <VueMultiselect
                        v-model="partnerUserSelected"
                        placeholder="Buscar um parceiro"
                        select-label="Pressione o enter ou clique para selecionar"
                        deselect-label="Pressione o enter ou clique para deselecionar"
                        label="name"
                        track-by="id"
                        :options="partnerUserOptionsFiltered"
                        :multiple="false"
                        :taggable="true"
                     ></VueMultiselect>
                  </div>
               </div>
            </div>

            <div class="card-body">
               <div class="row">
                  <div class="col-12 col-md-12 col-lg-9 order-2 order-md-1">
                     <div class="row">
                        <div class="col-12 col-md-6 col-sm-6">
                           <div class="info-box bg-light tooltip-commission ">
                              <div class="info-box-content">
                                 <span class="info-box-text text-center text-muted">{{ $t('total_commission_for_the_current_month') }}</span>
                                 <span class="info-box-number text-center text-muted mb-0">
                                    R$ {{ partnerInfoFormated.totalCommission.toLocaleString('pt-br', {minimumFractionDigits: 2}) }}
                                 </span>
                                 <span class="tooltiptext">
                                    Valor de comissão referente às recorrências e novas assinaturas.
                                 </span>
                              </div>
                           </div>
                        </div>
                        <div class="col-12 col-md-6 col-sm-6">
                           <div class="info-box bg-light">
                              <div class="info-box-content">
                                 <span class="info-box-text text-center text-muted">{{ $t('qty_of_new_subscriptions_in_the_current_month') }}</span>
                                 <span class="info-box-number text-center text-muted mb-0">
                                    {{ partnerInfo.QytOfnewSigForTheCurrentMonth }}
                                 </span>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="row">
                        <div class="col-12">
                           <div class="d-flex">
                              <h4 class="text-secondary text-bold">{{ `${$t('quantity_of')} ${$t('coupons')} ${$t('used')}` }}</h4>
                                 <p class="text-sm text-secondary mt-1 mx-2"> - {{ $t('in_the_current_month') }}</p>
                           </div>
                           <div class="mt-4">
                              <div class="card">
                                 <div class="card-body">
                                    <div class="row">
                                       <div class="col-md-7">
                                          <div v-if="totalCouponUsage <= 0" class="d-flex justify-content-center">{{ $t('no_coupons_used_this_month') }}</div>
                                          <div v-else class="chart-responsive">
                                             <canvas id="pieChart" height="150"></canvas>
                                          </div>
                                          <!-- ./chart-responsive -->
                                       </div>
                                       <!-- /.col -->
                                       <div class="col-md-5">
                                          <ul class="chart-legend clearfix text-secondary" style="font-size: 13px;">
                                             <li v-for="(couponInfo) in partnerInfoFormated.allCouponsInfo">
                                                <i class="far fa fa-tags text-success"></i>
                                                {{ couponInfo.couponName }} -
                                                {{ $t('commission') }}: R$ {{ (couponInfo.totalByCoupon * ( couponInfo.partnerPercentage / 100)).toLocaleString('pt-br', {minimumFractionDigits: 2}) }} -
                                                Total: R$ {{ couponInfo.totalByCoupon.toLocaleString('pt-br', {minimumFractionDigits: 2}) }}
                                             </li>
                                          </ul>
                                       </div>
                                       <!-- /.col -->
                                    </div>
                                    <!-- /.row -->
                                 </div>
                                 <!-- /.card-body -->

                              </div>
                              <!-- /.card -->
                           </div>
                        </div>
                        <!-- /graph -->

                        <div class="col-12">
                           <div class="d-flex">
                              <h6 class="text-secondary text-bold">{{ $t('commission_from_previous_months') }}</h6>
                           </div>
                           <div class="row">
                              <div v-for="(monthCommission) in monthsCommissionsValues" class="col-sm-3 col-6">
                                 <div class="description-block border-right">
                                    <span class="description-percentage text-success"><i class="fas fa-money-bill"></i></span>
                                    <h5 class="description-header">R$ {{ monthCommission.totalCommissionByMonth.toLocaleString('pt-br', {minimumFractionDigits: 2}) }}</h5>
                                    <span class="description-text">{{ getMonthName(monthCommission.month) }}</span>
                                 </div>
                                 <!-- /.description-block -->
                              </div>
                              <!-- /.col -->
                           </div>
                           <!-- /.row -->
                        </div>
                     </div>
                  </div>
                  <div class="col-12 col-md-12 col-lg-3 order-1 order-md-2 mt-2">
                     <h4 class="text-primary text-bold">{{ partnerInfo.name }}</h4>
                     <br>
                     <div class="text-muted">
                        <p class="text-sm">
                           <b style="font-size: 16px;">{{ $t('bank_details') }}</b>
                           <div v-if="!haveBankingInformation">
                              <span class="d-block">{{ $t('no_bank_details_yet') }}</span>
                           </div>
                           <div v-else>
                              <span class="d-block">
                                 Pix: {{ partnerInfo.bankingInformation.pixKey }}
                              </span>
                              <span class="d-block">
                                 {{ $t('full_name') }}: {{ partnerInfo.bankingInformation.fullName }}
                              </span>
                              <span class="d-block">
                                 {{ $t('bank') }}: {{ partnerInfo.bankingInformation.bank }}
                              </span>
                              <span class="d-block">
                                 {{ $t(`${partnerInfo.bankingInformation.typeOfAccount}`) }}: {{ partnerInfo.bankingInformation.bankAccount }}
                              </span>
                              <span class="d-block">
                                 {{ $t('agency') }}: {{ partnerInfo.bankingInformation.agency }}
                              </span>
                           </div>
                        </p>
                     </div>

                     <h6 class="mt-5 text-lg text-muted">Cupons do Parceiro</h6>
                     <ul class="list-unstyled text-sm">
                        <li v-for="(couponInfo) in partnerInfoFormated.allCouponsInfo" :key="couponInfo.couponUuid">
                           <p class="btn-link text-secondary ml-2"><i class="far fa fa-tags text-warning"></i>
                              {{ couponInfo.couponName }} - {{ couponInfo.partnerPercentage }}% {{ $t('of_commission') }}
                           </p>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
            <!-- /.card-body -->
         </div>
         <!-- /.card -->

      </div>
   </div>
</template>

<script>
import salesCommissionService from "@/modules/dashboard/modules/salesCommission/services/sales-commission-service";
import { getMonthName } from "../../../../../utils/get-month-name";
import VueMultiselect from "vue-multiselect";
import {mapState} from "vuex";
import { extractYearAndMonth } from "@/utils/report-service-methods";
import { currentMonth, currentYear } from "@/utils/report-service-methods";

export default {
   name: "SalesCommissionPanel",
   components: { VueMultiselect },
   data () {
     return {
        partnerInfo: null,
        partnerUserSelected: null,
        isLoading: false,
        tableData: [],
        tableLabels: [],
        dataLabels: [],
        isFirstPartnerChange: true,
        currentYear: null,
        currentMonth: null
     }
   },
   computed: {
      ...mapState({
         partnerUserOptions: state => state.salesCommission.salesCommissions.partnerUser.data[0],
      }),
      partnerInfoFormated() {
         return this.formatFloats(this.partnerInfo);
      },
      partnerUserOptionsFiltered() {
         const partners = this.partnerUserOptions.filter((user) =>
            user.hasOwnProperty('couponsUuid') && user.couponsUuid.length > 0
         );

         return partners;
      },
      haveBankingInformation() {
         return this.partnerInfo.hasOwnProperty('bankingInformation') ? true : false;
      },
      monthsCommissionsValues() {
         const monthsCommissionsValues = this.partnerInfoFormated.monthsCommissionsValues.slice(1).reverse();

         return monthsCommissionsValues;
      },
      totalCouponUsage () {
         let total = 0;
         this.partnerInfo.allCouponsInfo.forEach((coupon) => {

            coupon.usageHistory.forEach((newSig) => {

               const { year, month } = extractYearAndMonth(newSig.date);
               if (this.currentYear == year && this.currentMonth == month )
                  total++;
            });
            //total += coupon.usageHistory.length;
         });

         return total;
      }
   },
   watch: {
      async partnerUserSelected (newPartner) {
         if (!this.isFirstPartnerChange) {
            this.partnerInfo = await salesCommissionService.getAllInfoAboutCommissionByUserUuidPanel(newPartner.id);
            this.loadChartValues();
         }
         this.isFirstPartnerChange = false;
      }
   },
   async created() {
      this.isLoading = true;
      this.currentMonth = currentMonth();
      this.currentYear = currentYear();

      try {
         this.partnerInfo = await salesCommissionService.getAllInfoAboutCommissionByUserUuidPanel(this.partnerUserOptionsFiltered[1].id);
         this.partnerUserSelected = this.partnerUserOptionsFiltered[1];
      }
      catch (e) {
         console.error('Error sales commission panel', e)
      } finally {
         this.isLoading = false
      }

      this.loadChartValues();
   },
   unmounted() {},
   methods: {
      getMonthName,
      formatFloats(obj) {
         if (Array.isArray(obj)) {
            return obj.map(item => this.formatFloats(item));
         } else if (typeof obj === "object" && obj !== null) {
            const formattedObj = {};
            for (const key in obj) {
               if (typeof obj[key] === "number") {
                  // Check if the value is a float
                  if (obj[key] % 1 !== 0) {
                     formattedObj[key] = parseFloat(obj[key].toFixed(2)); // Round to 2 decimals and change . by , and , by .
                  } else {
                     formattedObj[key] = parseFloat(obj[key].toFixed(2)); // Round to 2 decimals and change . by , and , by .
                  }
               } else if (typeof obj[key] === "object" || Array.isArray(obj[key])) {
                  formattedObj[key] = this.formatFloats(obj[key]); // Recursively format nested objects/arrays
               } else {
                  formattedObj[key] = obj[key]; // Keep other values unchanged
               }
            }
            return formattedObj;
         }
         return obj;
      },
      loadChartValues () {
         this.resetTableInfo();
         this.partnerInfoFormated.allCouponsInfo.forEach((partnerInfo) => {
            this.tableLabels.push(partnerInfo.couponName);

            const dataLabel = `R$ ${partnerInfo.totalCommissionByMonth}, e usado`;
            this.dataLabels.push(dataLabel)
            this.tableData.push(partnerInfo.usageHistory.length);
         });

         const data = {
            labels: this.tableLabels,
            datasets: [
               {
                  label: 'Datasets',
                  data: this.tableData,
                  backgroundColor: [
                     'rgb(54, 162, 235)',
                     'rgb(255, 205, 86)',
                     'rgb(255, 137, 86)',
                     'rgb(148,97,176)',
                     'rgb(255,86,86)',
                     'rgb(103,255,86)',
                     'rgb(165,224,183)',
                  ],
                  hoverOffset: 2
               }
            ]
         };

         setTimeout(() => {
            const ctx = document.getElementById('pieChart');

            if (window.pieChartInstance) {
               window.pieChartInstance.destroy();
            }

            window.pieChartInstance = new Chart(ctx, {
               type: 'pie',
               data: data,
               options: {
                  responsive: true,
                  plugins: {
                     legend: {
                        display: true,
                        position: 'bottom',
                     }
                  }
               }
            });
         }, 1000);
      },
      resetTableInfo () {
         this.tableData = [];
         this.tableLabels = [];
      }
   }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>
.tooltip-commission {
   position: relative; /* Needed to position the tip text */
   cursor: pointer;
}

.tooltip-commission .tooltiptext {
   visibility: hidden; /* Hides text by default */
   background-color: #333; /* Background color */
   color: #fff; /* Text color */
   text-align: center; /* Center the text */
   border-radius: 4px; /* Round the corners */
   padding: 5px 10px; /* Internal spacing */
   font-size: 13px;
   position: absolute; /* Position in relation to .tooltip */
   z-index: 1; /* Ensures that the tooltip appears above other elements */
   bottom: 90%; /* Adjusts the vertical position */
   left: 50%; /* Centered horizontally */
   transform: translateX(-30%); /* Adjust the center of the tooltip */
   opacity: 0; /* Makes invisible by default */
   transition: opacity 0.3s; /* Adds a smooth transition */
}

.tooltip-commission:hover .tooltiptext {
   visibility: visible; /* Displays text on mouseover */
   opacity: 1; /* Make the tooltip appear */
}
</style>
