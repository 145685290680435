<template>
   <div v-if="showModal" class="modals-banck-account" :id="modalId">
      <div class="modal-banck-account-mask modal-banck-account-overlay">
         <div class="modal-banck-account-wrapper">
            <div class="modal-banck-account-container">
               <div class="modal-banck-account-header">
                  <h3>{{ $t('add_account_info') }}</h3>
                  <br/>
                  <button class="close text-danger" @click="cancelAction">&times;</button>
               </div>
               <div class="modal-banck-account-body">

                  <div class="row">
                     <div class="col-12">
                        <div class="form-group mb-4 mt-4">
                           <label for="exampleUserName">{{ $t('full_name') }}*</label>
                           <input v-model="v$.bankingInformation.fullName.$model" type="text" class="form-control" placeholder="Nome">
                           <div v-if="v$.bankingInformation.fullName.$error" class="col-12 text-danger font-italic small-text">
                              <span >{{ errorMessage(v$.bankingInformation.fullName.$errors) }}</span>
                           </div>
                        </div>
                     </div>

                     <div class="col-12">
                        <div class="form-group mb-4">
                           <label for="exampleUserName">{{ $t('pix_key') }}*</label>
                           <input
                              v-model="v$.bankingInformation.pixKey.$model"
                              type="text" class="form-control"
                              placeholder="(33) 99999-9999 + email@exemplo.com.br">
                           <div v-if="v$.bankingInformation.pixKey.$error" class="col-12 text-danger font-italic small-text">
                              <span >{{ errorMessage(v$.bankingInformation.pixKey.$errors) }}</span>
                           </div>
                        </div>
                     </div>

                     <div class="col-6">
                        <div class="form-group mb-4">
                           <label for="exampleUserEmail">{{ $t('bank') }}*</label>
                           <input v-model="v$.bankingInformation.bank.$model"  type="text" class="form-control" placeholder="Bradesco">
                           <div v-if="v$.bankingInformation.bank.$error" class="col-12 text-danger font-italic small-text">
                              <span >{{ errorMessage(v$.bankingInformation.bank.$errors) }}</span>
                           </div>
                        </div>
                     </div>

                     <div class="col-6">
                        <div class="form-group mb-4">
                           <label for="exampleUserEmail">{{ $t('bank_account') }}*</label>
                           <input v-model="v$.bankingInformation.bankAccount.$model"  type="text" class="form-control" placeholder="72.347-2">
                           <div v-if="v$.bankingInformation.bankAccount.$error" class="col-12 text-danger font-italic small-text">
                              <span >{{ errorMessage(v$.bankingInformation.bankAccount.$errors) }}</span>
                           </div>
                        </div>
                     </div>

                     <div class="col-6">
                        <div class="form-group mb-4">
                           <label for="exampleUserEmail">{{ $t('agency') }}*</label>
                           <input v-model="v$.bankingInformation.agency.$model"  type="text" class="form-control" placeholder="0062-8">
                           <div v-if="v$.bankingInformation.agency.$error" class="col-12 text-danger font-italic small-text">
                              <span >{{ errorMessage(v$.bankingInformation.agency.$errors) }}</span>
                           </div>
                        </div>
                     </div>

                     <div class="col-6">
                        <div class="form-group mb-4">
                           <label for="exampleUserEmail">{{ $t('type_of_account') }}*</label>
                           <select v-model="v$.bankingInformation.typeOfAccount.$model" class="form-control">
                              <option disabled value="">{{ $t('choose_one_option') }}</option>
                              <option value="current_account">
                                 {{ $t('current_account') }}
                              </option>
                              <!--                                 :class="[{'selected': selectedProfile(`${user.profile}`)}]"-->
                              <option value="savings" >
                                 {{ $t('savings_account') }}
                              </option>
                           </select>
                           <div v-if="v$.bankingInformation.typeOfAccount.$error" class="col-12 text-danger font-italic small-text">
                              <span >{{ errorMessage(v$.bankingInformation.typeOfAccount.$errors) }}</span>
                           </div>
                        </div>
                     </div>
                  </div>

               </div>
               <div class="modal-footer">
                  <button
                     type="button"
                     class="btn btn-outline-secondary btn-sm mx-4 mt-2 mb-3 text-bold"
                     data-dismiss="modal"
                     @click="cancelAction"
                  >
                     {{ $t('cancel') }}
                  </button>

                  <button type="button" @click="confirmAction" :disabled="loading" class="btn btn-outline-success btn-sm mt-2 mb-3 text-bold">
                     {{ $t('save') }}
                     <div v-if="loading" class="spinner-border spinner-border-sm float-right ml-1" role="status" aria-hidden="true"></div>
                  </button>

               </div>
            </div>
         </div>
      </div>
   </div>
</template>

 <script>
 import {email, minLength, required} from "@vuelidate/validators";
 import {useVuelidate} from "@vuelidate/core";
 import validationMixin from "@/mixins/validationMixin";
 import userService from "@/modules/dashboard/modules/user/services/user-service";

export default {
   name: 'AddBankAccountInfo',
   props: {
      modalId: {
         type: String,
         required: true,
      },
      showModal: {
         type: Boolean,
         default: false,
      },
      userId: {
         type: String,
         required
      },
      userBankingInformation: {
         type: Object,
         default: {
            fullName: '',
            pixKey: '',
            bank: '',
            bankAccount: '',
            agency: '',
            typeOfAccount: ''
         }
      }
   },
   setup() {
      return { v$: useVuelidate() }
   },
   mixins: [
      validationMixin
   ],
   data() {
      return {
         bankingInformation: {
            fullName: '',
            pixKey: '',
            bank: '',
            bankAccount: '',
            agency: '',
            typeOfAccount: ''
         },
         loading: false,
      };
   },
   watch: {
      userBankingInformation(newVal) {

         if (newVal.fullName != ''){
            this.bankingInformation.fullName = newVal.fullName;
            this.bankingInformation.pixKey = newVal.pixKey;
            this.bankingInformation.bank = newVal.bank;
            this.bankingInformation.bankAccount = newVal.bankAccount;
            this.bankingInformation.agency = newVal.agency;
            this.bankingInformation.typeOfAccount = newVal.typeOfAccount;
         }
      },
   },
   validations () {
      return {
         bankingInformation: {
            fullName: {
               required,
               minLength: minLength(6)
            },
            pixKey: {
               required,
               minLength: minLength(3)
            },
            bank: {
               required,
               minLength: minLength(2)
            },
            bankAccount: {
               required,
               minLength: minLength(3)
            },
            agency: {
               required,
               minLength: minLength(3)
            },
            typeOfAccount: {
               required
            }
         }
      }
   },
   mounted() {
      this.loading = false;
   },
   beforeDestroy() {
      this.loading = false;
   },
   methods: {
      async confirmAction() {
         this.loading = true;

         this.v$.$touch();
         if (this.v$.$error) {
            this.loading = false;
            return
         }
         // create user here
         await userService.includeBankingInformation(this.bankingInformation, this.userId);

         this.reset();
         this.v$.$reset();

         this.loading = false;
         this.$emit("actionConfirmed");
      },
      cancelAction() {
         this.loading = false;
         this.reset();
         this.v$.$reset();
         this.$emit("actionCancelled");
      },
      reset () {
         this.bankingInformation = {
            fullName: '',
            pixKey: '',
            bank: '',
            bankAccount: '',
            agency: '',
            typeOfAccount: ''
         }
      }
   }
};
</script>

<style scoped>

.form-group {
   display: flex;
   flex-direction: column; /* Coloca o label acima do input */
   align-items: flex-start; /* Alinha o label à esquerda */
   margin-bottom: 16px;
}

.small-text{
   font-size: 11px;
}

.modals-banck-account {
   font-family: Avenir, Helvetica, Arial, sans-serif;
   text-align: center;
   margin-top: 60px;
}

.modal-banck-account-mask {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.5);
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 10000;
}

.modal-banck-account-overlay {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: rgba(0, 0, 0, 0.5);
   display: flex;
   align-items: center;
   justify-content: center;
   z-index: 10000;
}

.modal-banck-account-wrapper {
   box-sizing: border-box;
}

.modal-banck-account-container {
   width: 650px;
   padding: 20px;
   background: white;
   border-radius: 5px;
   box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
   box-sizing: border-box;
}

.modal-banck-account-header {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
   border-bottom: 1px solid #e5e5e5;
   padding-bottom: 10px;
}

.modal-banck-account-header h3 {
   margin: 0;
}

.modal-banck-account-header .close {
   position: absolute;
   margin-left: 550px;
   border: none;
   background: none;
   font-size: 1.5em;
   cursor: pointer;
   color: #d9124c;
}

.modal-banck-account-body {
   max-height: 60vh;
   overflow-y: auto;
   margin-bottom: 15px;
}
</style>
