<template>
   <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
         <div class="container-fluid">
            <div class="row mb-2">
               <div class="col-sm-6">
                  <h1 class="m-0">{{ $t('sales_commission') }}</h1>
               </div><!-- /.col -->
               <div class="col-sm-6">
                  <ol class="breadcrumb float-sm-right">
                     <li class="breadcrumb-item active">{{ $t('dashboard') }}</li>
                     <li class="breadcrumb-item active">{{ $t('sales_commission') }}</li>
                  </ol>
               </div><!-- /.col -->
            </div><!-- /.row -->
         </div><!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
         <div class="container-fluid">
            <!-- /.row -->
            <div class="row">
               <div class="col-12 col-sm-12 col-lg-12">
                  <div class="card card-secondary card-tabs">
                     <div class="card-header p-0 pt-1">
                        <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
                           <li class="nav-item">
                              <a
                                 class="nav-link active"
                                 id="custom-tabs-one-home-tab"
                                 data-toggle="pill"
                                 href="#custom-tabs-one-home"
                                 role="tab"
                                 aria-controls="custom-tabs-one-home"
                                 aria-selected="true"
                                 @click.prevent="deactivatePanel"
                              >
                                 Tabela
                              </a>
                           </li>
                           <li class="nav-item">
                              <a class="nav-link"
                                 id="custom-tabs-one-profile-tab"
                                 data-toggle="pill"
                                 href="#custom-tabs-one-profile"
                                 role="tab"
                                 aria-controls="custom-tabs-one-profile"
                                 aria-selected="false"
                                 @click.prevent="activePanel"
                              >
                                 Painel
                              </a>
                           </li>
                        </ul>
                     </div>
                     <div class="card-body">
                        <div class="tab-content" id="custom-tabs-one-tabContent">
                           <div
                              class="tab-pane fade show active"
                              id="custom-tabs-one-home"
                              role="tabpanel"
                              aria-labelledby="custom-tabs-one-home-tab"
                           >
                              <SalesCommissionTable />
                           </div>
                           <div
                              class="tab-pane fade"
                              id="custom-tabs-one-profile"
                              role="tabpanel"
                              aria-labelledby="custom-tabs-one-profile-tab"
                           >
                              <div v-if="panelIsActive">
                                 <SalesCommissionPanel />
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="card-footer clearfix py-1">

                     </div>
                  </div>
               </div>
            </div>
            <!-- /.row -->
         </div>
      </section>
      <!-- /.content -->
   </div>
</template>

<script>
import ConfirmationModal from "@/modules/dashboard/components/ConfirmationModal.vue";
import Pagination from "@/modules/dashboard/components/Pagination.vue";
import SalesCommissionTable from "@/modules/dashboard/modules/salesCommission/components/SalesCommissionTable.vue";
import SalesCommissionPanel from "@/modules/dashboard/modules/salesCommission/components/SalesCommissionPanel.vue";

export default {
   name: "SalesCommissions",
   components: { SalesCommissionPanel, SalesCommissionTable, Pagination, ConfirmationModal },
   data () {
      return {
         panelIsActive: false
      }
   },
   methods: {
      activePanel() {
         this.panelIsActive = true;
      },
      deactivatePanel() {
         this.panelIsActive = false;
      }
   }
}
</script>

<style scoped>

</style>
