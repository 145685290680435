const getMonthName = (monthNumber) => {
   const months = [
      'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
      'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
   ];

   if (monthNumber >= 1 && monthNumber <= 12) {
      return months[monthNumber - 1];
   } else {
      return 'Número do mês inválido';
   }
}

export { getMonthName }
