<template>
   <div class="row">
      <div class="col-12">
         <div class="card">
            <div class="card-header">
               <div class="card-tools">
               </div>
            </div>
            <!-- /.card-header -->

            <div v-if="isLoading" class="d-flex justify-content-center">{{ $t('loading') }}</div>

            <div v-else class="card-body table-responsive p-0">
               <table class="table table-hover text-nowrap">
                  <thead>
                  <tr>
                     <th>{{ $t('partner') }}</th>
                     <th>{{ $t('coupons') }}</th>
                     <th>{{ $t('total_for_the_month') }} ({{ month }})</th>
                     <th>{{ $t('amount_to_be_transferred_for_the_month') }} ({{ month }})</th>
                     <th>{{ $t('action') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(partnerInfo, idx) in partnersInfo.data" :key="idx">
                     <td>{{ partnerInfo.name }}</td>
                     <td class="width-limit">
                        <span v-for="(coupon) in partnerInfo.couponsName">
                           {{ coupon ? `${coupon} - ` : `` }}
                        </span>
                     </td>
                     <td>R$ {{ partnerInfo.total.toLocaleString('pt-br', {minimumFractionDigits: 2}) }}</td>
                     <td>R$ {{ partnerInfo.totalCommission.toLocaleString('pt-br', {minimumFractionDigits: 2}) }}</td>
                     <td>
                        <div class="row">
                           <button
                              type="button"
                              @click.prevent="showAddBackAccountInfoModal(partnerInfo)"
                              class="btn btn-sm btn-outline-info text-bold"
                           >
                              {{ $t('bank_account') }}
                           </button>
                        </div>
                     </td>
                  </tr>
                  </tbody>
               </table>
            </div>
            <!-- /.card-body -->

            <div class="card-footer clearfix">
               <pagination
                  v-if="!isSearching"
                  :totalPages="totalPages"
                  :currentPage="currentPage"
                  :isFirstPage="isFirstPage"
                  :isLastPage="isLastPage"
                  :adjacentPages="adjacentPages"
                  :tokenPages="tokenPages"
                  :isLoading="isLoadingPagination"
                  @page-selected="handlePageSelected"
               />
            </div>
            <!-- /.card-footer -->

         </div>
         <!-- /.card -->
      </div>

      <AddBankAccountInfo
         :modal-id="modalConfirmationActionId"
         :show-modal="showModal"
         :user-id="partnerUuid"
         :user-banking-information="bankingInformation"
         @actionConfirmed="showToast"
         @actionCancelled="closeModal"
      />

   </div>
   <!-- /.row -->
</template>

<script>
import ConfirmationModal from "@/modules/dashboard/components/ConfirmationModal.vue";
import Pagination from "@/modules/dashboard/components/Pagination.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import salesCommissionService from "../services/sales-commission-service";
import AddBankAccountInfo from "@/modules/dashboard/modules/salesCommission/components/AddBankAccountInfo.vue";
import { useToast } from "vue-toastification";

export default {
   name: "SalesCommissionTable",
   components: {AddBankAccountInfo, Pagination, ConfirmationModal},
   setup() {
      const toast = useToast()
      return { toast }
   },
   data () {
      return {
         partnersInfo: null,
         salesCommissionSelected: null,
         modalConfirmationActionId: "modal-confirmation-action-sales-commission",
         searchQuery: "",
         searchTimeout: null,
         adjacentPagesCount: 2,
         isLoading: false,
         isLoadingPagination: false,
         partnerUuid: '',
         isSearching: false,
         showModal: false,
         bankingInformation: {
            fullName: '',
            pixKey: '',
            bank: '',
            bankAccount: '',
            agency: '',
            typeOfAccount: ''
         }
      }
   },
   computed: {
      ...mapState({
         currentPage: state => state.salesCommission.salesCommissions.partnerUser.pagination.currentPage,
         tokenPages: state => state.salesCommission.salesCommissions.partnerUser.pagination.tokenPages,
         totalPages: state => state.salesCommission.salesCommissions.partnerUser.pagination.totalPages
      }),
      adjacentPages() {
         const currentIndex = this.tokenPages.findIndex((page) => page.page === this.currentPage);

         const startIdx = Math.max(0, currentIndex - this.adjacentPagesCount);
         const endIdx = Math.min(this.tokenPages.length - 1, currentIndex + this.adjacentPagesCount);

         const pages = this.tokenPages.slice(startIdx, endIdx + 1);

         return pages;
      },
      isFirstPage() {
         return this.currentPage === 1;
      },
      isLastPage() {
         return this.currentPage === this.totalPages;
      },
      month () {
         let now = new Date();
         return now.getMonth() + 1;
      }
   },
   async created() {
      this.setCurrentPage(1)
      this.isLoading = true;
      this.isLoadingPagination = true;

      await this.getAllPartnersPaginatedTable();

      this.partnersInfo = await salesCommissionService.getPartnersSalesCommissionsInfoTable();
      this.isLoading = false;
      this.isLoadingPagination = false;
   },
   methods: {
      ...mapActions(['getAllPartnersPaginatedTable']),
      ...mapMutations({
         setCurrentPage: 'SET_SALES_COMMISSION_CURRENT_PAGE',
         setPaginationInfo: 'SET_SALES_COMMISSION_PAGINATION_INFO'
      }),
      async handlePageSelected (action) {
         let pageIndex;

         if (action === 'first' && !this.isFirstPage) {
            pageIndex = 1;
         } else if (action === 'prev' && !this.isFirstPage) {
            this.setCurrentPage(this.currentPage - 1);
            pageIndex = this.currentPage;
         } else if (action === 'next' && !this.isLastPage) {
            this.setCurrentPage(this.currentPage + 1);
            pageIndex = this.currentPage;
         } else if (action === 'last' && !this.isLastPage) {
            pageIndex = this.totalPages;
         } else if (typeof action === 'number') {
            pageIndex = action;
         }

         if (pageIndex) {
            const targetPage = this.tokenPages.find((page) => page.page === pageIndex);

            if (targetPage) {
               this.isLoading = true;
               this.setCurrentPage(targetPage.page);
               this.partnersInfo = await salesCommissionService.getPartnersSalesCommissionsInfoTable(targetPage.token);
               this.isLoading = false;
            }
         }
      },
      closeModal() {
         this.showModal = false;
      },
      showAddBackAccountInfoModal(partner) {
         this.partnerUuid = partner.id;
         if (partner.hasOwnProperty('bankingInformation'))
            this.bankingInformation = partner.bankingInformation
         else
            this.bankingInformationReset();

         this.showModal = true;
      },
      async showToast() {
         this.closeModal();
         this.isLoading = true;
         await this.getAllPartnersPaginatedTable();

         this.partnersInfo = await salesCommissionService.getPartnersSalesCommissionsInfoTable();
         this.isLoading = false;
         this.toast.success(this.$t("bank_details_added_successfully"));
      },
      bankingInformationReset() {
         this.bankingInformation = {
            fullName: '',
            pixKey: '',
            bank: '',
            bankAccount: '',
            agency: '',
            typeOfAccount: ''
         }
      }
   }
}
</script>

<style scoped>
.width-limit {
   max-width: 330px;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
}
</style>
